/* eslint-disable prettier/prettier */

// .closest
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

;(() => {
  const dismissModal = event => {
    event.preventDefault()

    const targetDetails = event.target.closest('details')

    targetDetails && targetDetails.removeAttribute('open')
  }

  const openModal = event => {
    event.preventDefault()

    const { target } = event

    if (target.dataset.dismissModal) return

    const targetDetails = target.closest('details')

    if (targetDetails) {
      targetDetails.setAttribute('open', true)
    }
  }

  const summary = document.querySelectorAll('summary')
  const dismissModalBtns = document.querySelectorAll('[data-dismiss-modal="true"]')

  summary.forEach(summary => summary.addEventListener('click', openModal))
  dismissModalBtns.forEach(button => button.addEventListener('click', dismissModal))
})()

// Polyfill element .toggleAttribute
// Needed for safari <= 11 and android < v69
// https://developer.mozilla.org/en-US/docs/Web/API/Element/toggleAttribute
if (!Element.prototype.toggleAttribute) {
  Element.prototype.toggleAttribute = function(name, force) {
    if(force !== void 0) force = !!force;

    if (this.hasAttribute(name)) {
      if (force) return true;

      this.removeAttribute(name);
      return false;
    }

    if (force === false) return false;

    this.setAttribute(name, "");
    return true;
  };
}
